/*.signup-container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    height: 100vh;*/
/*    background-color: white;*/
/*}*/

/*.signup-card {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    background: white;*/
/*    border-radius: 10px;*/
/*    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);*/
/*    max-width: 900px;*/
/*    width: 100%;*/
/*}*/

/*.invalid {*/
/*    border: 1px solid red;*/
/*}*/

/*.signup-image-container {*/
/*    flex-basis: 50%;*/
/*    max-width: 50%;*/
/*}*/

/*.signup-image {*/
/*    width: 100%;*/
/*    height: auto;*/
/*    border-radius: 10px 0 0 10px;*/
/*}*/

/*.signup-form {*/
/*    flex-basis: 50%;*/
/*    padding: 40px;*/
/*    max-width: 50%;*/
/*    background-color: white;*/
/*}*/

/*!* Styles for smaller screens (mobile) *!*/
/*@media screen and (max-width: 768px) {*/
/*    .signup-card {*/
/*        flex-direction: column;*/
/*    }*/

/*    .signup-image-container {*/
/*        display: none;*/
/*    }*/

/*    .signup-form {*/
/*        width: 100%;*/
/*        max-width: none;*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/

/*.signup-form h1 {*/
/*    text-align: center;*/
/*    margin-bottom: 10px;*/
/*    color: #2a6499;*/
/*    font-weight: bold;*/
/*    font-size: 20px;*/

/*    font-family: Arial, sans-serif;*/
/*}*/

/*.signup-form input {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    margin: 10px 0;*/
/*    border: 1px solid #ddd;*/
/*    border-radius: 4px;*/
/*    box-sizing: border-box; !* Makes sure padding doesn't affect overall width *!*/
/*}*/

/*.signup-form button {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: none;*/
/*    background-color: #2a6499;*/
/*    color: white;*/
/*    font-size: 16px;*/
/*    cursor: pointer;*/
/*    border-radius: 4px;*/
/*    margin-top: 10px;*/
/*}*/

/*.signup-form button:hover {*/
/*    background-color: #3c7fbf; !* Darker shade for hover effect *!*/
/*}*/

/*!* Existing styles... *!*/

/*.login-message {*/
/*    text-align: center;*/
/*    margin-top: 10px;*/
/*    font-size: 0.9em;*/
/*    color: #333333;*/
/*}*/

/*.login-message a {*/
/*    color: #2a6499;*/
/*    text-decoration: none;*/
/*}*/

/*.login-message a:hover {*/
/*    text-decoration: underline;*/
/*}*/

/*!**/

/*    Loader*/

/**!*/

/*.signup-action-container {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    gap: 30px; !* Adjusts the space between the button and the loader *!*/
/*}*/

/*.signup-btn {*/
/*    width: auto; !* Adjust according to your layout needs *!*/
/*    flex-grow: 1; !* Ensures button uses available space *!*/
/*}*/

/*.password-field{*/
/*    position: relative;*/
/*    display: flex; !* Use flex to align icon with the input *!*/
/*    align-items: center;*/
/*    width: 100%;*/
/*}*/

/*.password-field input{*/

/*    flex-grow: 1; !* Input should grow and fill the space *!*/

/*}*/

/*.password-field i{*/
/*    margin-left: -30px; !* Position the icon inside the input field *!*/
/*    cursor: pointer;*/
/*    position: absolute;*/
/*    right: 10px;*/
/*    top: 100%;*/
/*    transform: translateY(-50%);*/

/*}*/

/*.loader {*/
/*    color: #ffffff;*/
/*    font-size: 5px;*/
/*    text-indent: -99999em;*/
/*    margin: 0; !* Adjust for alignment *!*/
/*    position: relative;*/
/*    width: 5px; !* Scaled up for visibility *!*/
/*    height: 5px; !* Scaled up for visibility *!*/
/*    box-shadow: inset 0 0 0 1px;*/
/*    transform: translateZ(0);*/
/*    animation: load6 1.7s infinite ease, round 1.7s infinite ease;*/
/*}*/

/*@keyframes load6 {*/
/*    0%,*/
/*    100% {*/
/*        box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em rgba(0, 51, 102, 0.2), 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.5), -1.8em -1.8em 0 0em #003366;*/
/*    }*/
/*    12.5% {*/
/*        box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em #003366, 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.2), -1.8em -1.8em 0 0em rgba(0, 51, 102, 0.5);*/
/*    }*/
/*}*/

/*@keyframes round {*/
/*    0% {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

/*!* OTP *!*/

/*.otp-container {*/
/*    display: flex;*/
/*    justify-content: center; !* Aligns OTP boxes to the center *!*/
/*    gap: 15px; !* Provides space between boxes *!*/
/*}*/

/*.otp-input {*/
/*    width: 50px; !* Adjusted size for a more balanced appearance *!*/
/*    height: 50px;*/
/*    text-align: center;*/
/*    font-size: 24px;*/
/*    border: 2px solid #ccc;*/
/*    border-radius: 5px; !* Slightly rounded corners for modern look *!*/
/*    transition: border-color 0.3s, box-shadow 0.3s;*/
/*    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); !* Soft shadow for depth *!*/
/*}*/

/*.otp-input:focus {*/
/*    border-color: #0096ff; !* Bright focus color *!*/
/*    box-shadow: 0 0 8px rgba(0, 150, 255, 0.5); !* Glowing effect to indicate focus *!*/
/*    outline: none;*/
/*}*/

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotham');

/* Background: Subtle light gradient */
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f7f7f7, #d55f5f);
}

/* Card: Floating effect with rounded corners */
.signup-card {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 20px;
    /*box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);*/
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);

    max-width: 900px;
    width: 100%;
    overflow: hidden;
}

/* Image Container */
.signup-image-container {
    flex-basis: 50%;
    max-width: 50%;
    overflow: hidden;
}

.signup-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* Form Styling */
.signup-form {
    flex-basis: 50%;
    padding: 40px;
    max-width: 50%;
    background-color: #FFFFFF;
}

/* Headings: League Spartan */
.signup-form h1 {
    font-family: 'League Spartan', sans-serif;
    font-size: 20px;
    color: #d55f5f;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
    text-transform: uppercase;
}

/* Input Fields: Modern flat design */
.signup-form input {
    width: 100%;
    padding: 12px 16px;
    margin: 12px 0;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-family: 'Gotham', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.signup-form input:focus {
    border-color: #d55f5f;
    box-shadow: 0 0 10px rgb(147, 100, 100);
    outline: none;
}

/* Error Message for Invalid Input */
.error-message {
    font-family: 'Gotham', sans-serif;
    color: red;
    font-size: 14px;
    margin-top: -8px;
}

/* Buttons: Professional style */
.signup-form button {
    width: 100%;
    padding: 12px;
    border: none;
    background-color: #d55f5f;
    color: #000000;
    font-size: 16px;
    font-family: 'League Spartan', sans-serif;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-transform: uppercase;
}

.signup-form button:hover {
    background-color: #d55f6f;
    transform: scale(1.02);
}

.login-message {
    margin-top: 10px;
    text-align: center;
    font-size: 0.9em;
    color: #333333;
}

/* Links */
.login-message a {
    font-family: 'Gotham', sans-serif;
    color: #d55f5f;
    text-decoration: none;
    font-size: 14px;
}

.login-message a:hover {
    text-decoration: underline;
    color: #d55f6f;
}

/* Password Field Icon */
.password-field i {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #000000;
    transition: color 0.3s ease;
}

.password-field i:hover {
    color: #d55f5f;
}

/* OTP Inputs: Modern design */
.otp-container {
    display: flex;
    justify-content: center;
    gap: 12px;
}

.otp-input {
    width: 48px;
    height: 48px;
    text-align: center;
    font-size: 18px;
    border: 2px solid #ddd;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.otp-input:focus {
    border-color: #d55f5f;
    box-shadow: 0 0 8px rgba(250, 208, 73, 0.5);
    outline: none;
}

/* Responsiveness */
@media screen and (max-width: 768px) {
    .signup-card {
        flex-direction: column;
    }

    .signup-image-container {
        display: none;
    }

    .signup-form {
        width: 100%;
        max-width: none;
        border-radius: 20px;
    }
}

/*.loader {*/
/*    color: #ffffff;*/
/*    font-size: 5px;*/
/*    text-indent: -99999em;*/
/*    margin: 0; !* Adjust for alignment *!*/
/*    position: relative;*/
/*    width: 5px; !* Scaled up for visibility *!*/
/*    height: 5px; !* Scaled up for visibility *!*/
/*    box-shadow: inset 0 0 0 1px;*/
/*    transform: translateZ(0);*/
/*    animation: load6 1.7s infinite ease, round 1.7s infinite ease;*/
/*}*/

/*@keyframes load6 {*/
/*    0%,*/
/*    100% {*/
/*        box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em rgba(0, 51, 102, 0.2), 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.5), -1.8em -1.8em 0 0em #003366;*/
/*    }*/
/*    12.5% {*/
/*        box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em #003366, 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.2), -1.8em -1.8em 0 0em rgba(0, 51, 102, 0.5);*/
/*    }*/
/*}*/

/*@keyframes round {*/
/*    0% {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/