@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (max-width: 1326px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1220px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1030px) {
  html {
    font-size: 10px;
  }
}
